import React from "react";
import { Link, navigate } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "urql";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";

import imgLogo from "../../assets/image/logo.svg";
import imgTrial from "../../assets/image/svg/signup-trial-customization.svg";

const SAVE_TRIAL_PERSONALIZED = `
  mutation SaveTrialPersonalization($input: SaveTrialPersonalizationInput!) {
    saveTrialPersonalization(input: $input) {
      result
      errors {
        fullMessage
      }
    }
  }
`;

const Welcome = ({ location }) => {
  let signUpID = location.state && location.state.signUpID;
  let fullName = location.state && location.state.fullName;

  const [saveResult, saveTrialPersonalization] = useMutation(
    SAVE_TRIAL_PERSONALIZED
  );
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => {
    saveTrialPersonalization({ input: { id: signUpID, ...data } }).then(
      ({ data: saveTrialPersonalization }) => {
        navigate("/signup/done", {
          state: { signUpID: signUpID, fullName: fullName },
        });
      }
    );
  };

  return (
    <>
      <PageWrapper>
        <SEO title="Sign Up For Camelo"></SEO>

        <div className="sign-page bg-default-9">
          <header className="site-header bg-white">
            <Container fluid>
              <Row className="justify-content-center">
                <Col md="8" lg="5" xl="4">
                  <div className="brand text-center py-9">
                    <Link to="/">
                      <img src={imgLogo} alt="" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </header>

          <Container className="pb-25">
            <Row className="justify-content-center pt-25">
              <Col lg="10">
                <div
                  className="form-title text-center"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="100"
                >
                  <h2 className="title gr-text-4 mb-9">
                    Yay! We're almost there, {fullName}
                  </h2>
                  <p
                    className="gr-text-8 mb-13"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="900"
                  >
                    Let us know a bit more so we can create the best experience
                    for you
                  </p>
                </div>
              </Col>
            </Row>
            <p className="text-danger">
              {saveResult.error && saveResult.error.graphQLErrors[0].message}
            </p>
            <Row
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="900"
              className="justify-content-center bg-white border rounded-10 px-8 py-8 py-lg-16 shadow-1 mb-11"
            >
              <Col lg="4">
                <img src={imgTrial} className="img-fluid" alt="" />
              </Col>
              <Col lg="7" className="">
                <div className="main-block">
                  <div className="">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label
                          htmlFor="businessName"
                          className="gr-text-9 font-weight-bold text-blackish-blue"
                        >
                          What is your business name?
                        </label>
                        <input
                          className="form-control gr-text-9 border"
                          type="text"
                          id="businessName"
                          name="businessName"
                          ref={register({ required: true })}
                          placeholder=""
                          required
                        />
                        {errors.businessName && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group pt-4">
                        <label
                          htmlFor="industry"
                          className="gr-text-9 font-weight-bold text-blackish-blue"
                        >
                          What best describes your business?
                        </label>
                        <input
                          className="form-control gr-text-9 border"
                          type="text"
                          id="industry"
                          name="industry"
                          ref={register({ required: true })}
                          required
                          placeholder="i.e. A nail salon of 20 employees"
                        />
                        {errors.industry && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group pt-4">
                        <label
                          htmlFor="purposes"
                          className="gr-text-9 font-weight-bold text-blackish-blue"
                        >
                          What brings you to Camelo?
                        </label>
                        <input
                          className="form-control gr-text-9 border"
                          type="text"
                          id="purposes"
                          name="purposes"
                          ref={register({ required: true })}
                          required
                          placeholder="i.e. Want to create and share schedules"
                        />
                        {errors.purposes && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group button-block mb-2">
                        <input
                          type="submit"
                          value="Done"
                          value={saveResult.fetching ? "Saving..." : "Done"}
                          disabled={saveResult.fetching}
                          className="form-btn btn btn-primary gr-hover-y w-100"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Welcome;
